export const event = {
  greet: "socket:greet",
  join: "socket:join",
  own: "socket:join-own",
  disconnect: "socket:disconnect",
  room: {
    assign: "room:assign-admin-to-company",
    discharge: "room:discharge-admin-from-company",
    refetch: "room:refetch",
    latestChat: "room:latest-chat",
    update: "room:update",
    enter: "room:enter",
    leave: "room:leave",
    getChats: "room:get-chats",
    newChat: "room:new-chat",
    startTyping: "room:start-typing",
    stopTyping: "room:stop-typing",
  },
  notification: {
    new: "notification:new",
    read: "notification:read",
    deleted: "notification:deleted",
  },
  chat: {
    createUnread: "chat:create-unread",
    deleteUnread: "chat:delete-unread",
  },
  candidate: {
    register: "candidate:register",
  },
  resume: {
    request: "resume:request",
    updated: "resume:updated",
    verified: "resume:verified",
    rejected: "resume:rejected",
  },
  pic: {
    register: "pic:register",
    deleted: "pic:deleted",
  },
  company: {
    register: "company:register",
    published: "company:published",
    unlisted: "company:unlisted",
    deleted: "company:deleted",
  },
  application: {
    apply: "application:apply",
    scheduling: "application:scheduling",
    interview: "application:interview",
    visa: "application:visa",
    waiting: "application:waiting",
    departed: "application:departed",
    rejected: "application:rejected",
    cancelled: "application:cancelled",
    archived: "application:archived",
  },
  job: {
    created: "job:created",
    deleted: "job:deleted",
    archived: "job:archived",
    published: "job:published",
  },
}
