export const industries = [
  { id: '介護', sub: null },
  { id: 'ビルクリーニング', sub: null },
  {
    id: '素形材産業',
    sub: [
      '鋳造',
      '鍛造',
      'ダイカスト',
      '機械加工',
      '金属プレス加工',
      '工場板金',
      'めっき',
      'アルミニウム陽極酸化処理',
      '仕上げ',
      '機械検査',
      ' 機械保全',
      '塗装',
      '溶接',
    ],
  },
  {
    id: '産業機械製造業',
    sub: [
      '鋳造',
      '鍛造',
      'ダイカスト',
      '機械加工',
      '金属プレス加工',
      '鉄工',
      '工場板金',
      'めっき',
      '仕上げ',
      '機械検査',
      '機械保全',
      '電子機器組立て',
      '電気機器組立て',
      'プリント配線板製造',
      'プラスチック成形',
      '塗装',
      '溶接',
      '工業包装',
    ],
  },
  {
    id: '電気・電子情報関連産業',
    sub: [
      '機械加工',
      '金属プレス加工',
      '工場板金',
      'めっき',
      '仕上げ',
      '機械保全',
      '電子機器組立て',
      '電気機器組立て',
      'プリント配線板製造',
      'プラスチック成形',
      '塗装',
      '溶接',
      '工業包装',
    ],
  },
  {
    id: '建設業',
    sub: [
      '型枠施工',
      '左官',
      'コンクリート圧送',
      'トンネル推進工',
      '建設機械施工',
      '土工',
      '屋根ふき',
      '電気通信',
      '鉄筋施工',
      '鉄筋継手',
      '内装仕上げ',
      'とび',
      '建築大工',
      '配管',
      '建築板金',
      '保温保冷',
      '吹付ウレタン断熱',
      '海洋土木工',
    ],
  },
  {
    id: '造船・舶用業',
    sub: ['溶接', '塗装', '鉄工', '機械加工', '仕上げ', '電気機器組立て'],
  },
  { id: '自動車整備業', sub: null },
  { id: '航空業', sub: ['空港グランドハンドリング', '航空機整備'] },
  { id: '宿泊業', sub: null },
  { id: '農業', sub: ['耕種農業全般', '畜産農業全般'] },
  { id: '漁業', sub: ['漁業', '養殖業'] },
  { id: '飲食料品製造業', sub: null },
  { id: '外食業', sub: null },
  { id: 'その他', sub: null }, // used to be empty array []
]

export const titpIndustries = [
  // 1
  {
    id: '農業関係',
    sub: [
      {
        id: '耕種農業',
        sub: ['施設園芸', '畑作・野菜', '果樹'],
      },
      {
        id: '畜産農業',
        sub: ['養豚', '養鶏', '酪農'],
      },
    ],
  },

  // 2
  {
    id: '漁業関係',
    sub: [
      {
        id: '漁船漁業',
        sub: [
          'かつお一本釣り漁業',
          '延縄漁業',
          'いか釣り漁業',
          'まき網漁業',
          'ひき網漁業',
          '刺し網漁業',
          '定置網漁業',
          'かに・えびかご漁業',
        ],
      },
      {
        id: '養殖業',
        sub: ['ほたてがい・まがき養殖作業'],
      },
    ],
  },

  // 3
  {
    id: '建設関係',
    sub: [
      {
        id: 'さく井',
        sub: ['パーカッション式さく井工事作業', 'ロータリー式さく井工事作業'],
      },
      {
        id: '建築板金',
        sub: ['ダクト板金作業', '内外装板金作業'],
      },
      {
        id: '冷凍空気調和機器施工',
        sub: ['冷凍空気調和機器施工作業'],
      },
      {
        id: '建具製作',
        sub: ['木製建具手加工作'],
      },
      {
        id: '建築大工',
        sub: ['大工工事作業'],
      },
      {
        id: '型枠施工',
        sub: ['型枠工事作業'],
      },
      {
        id: '鉄筋施工',
        sub: ['鉄筋組立て作業'],
      },
      {
        id: 'とび',
        sub: ['とび作業'],
      },
      {
        id: '石材施工',
        sub: ['石材加工作業', '石張り作業'],
      },
      {
        id: 'タイル張り',
        sub: ['タイル張り作業'],
      },
      {
        id: 'かわらぶき',
        sub: ['かわらぶき作業'],
      },
      {
        id: '左官',
        sub: ['左官作業'],
      },
      {
        id: '配管',
        sub: ['建築配管作業', 'プラント配管作業'],
      },
      {
        id: '熱絶縁施工',
        sub: ['保温保冷工事作業'],
      },
      {
        id: '内装仕上げ施工',
        sub: [
          'プラスチック系床仕上げ工事作業',
          'カーペット系床仕上げ工事作業',
          '鋼製下地工事作業',
          'ボード仕上げ工事作業',
          'カーテン工事作業',
        ],
      },
      {
        id: 'サッシ施工',
        sub: ['ビル用サッシ施工作業'],
      },
      {
        id: '防水施工',
        sub: ['シーリング防水工事作業'],
      },
      {
        id: 'コンクリート圧送施工',
        sub: ['コンクリート圧送工事作業'],
      },
      {
        id: 'ウェルポイント施工',
        sub: ['ウェルポイント工事作業'],
      },
      {
        id: '表装',
        sub: ['壁装作業'],
      },
      {
        id: '建設機械施工',
        sub: ['押土・整地作業', '積込み作業', '掘削作業', '締固め作業'],
      },
      {
        id: '築炉',
        sub: ['築炉作業'],
      },
    ],
  },

  // 4
  {
    id: '食品製造関係',
    sub: [
      {
        id: '缶詰巻締',
        sub: ['缶詰巻締'],
      },
      {
        id: '食鳥処理加工業',
        sub: ['食鳥処理加工作業'],
      },
      {
        id: '加熱性水産加工食品製造業',
        sub: ['節類製造', '加熱乾製品製造', '調味加工品製造', 'くん製品製造'],
      },
      {
        id: '非加熱性水産加工食品製造業',
        sub: ['塩蔵品製造', '乾製品製造', '発酵食品製造'],
      },
      {
        id: '水産練り製品製造',
        sub: ['かまぼこ製品製造作業'],
      },
      {
        id: '牛豚食肉処理加工業',
        sub: ['牛豚部分肉製造作業'],
      },
      {
        id: 'ハム・ソーセージ・ベーコン製造',
        sub: ['ハム・ソーセージ・ベーコン製造作業'],
      },
      {
        id: 'パン製造',
        sub: ['パン製造作業'],
      },
      {
        id: 'そう菜製造業',
        sub: ['そう菜加工作業'],
      },
      {
        id: '農産物漬物製造業',
        sub: ['農産物漬物製造作業'],
      },
      {
        id: '医療・福祉施設給食製造',
        sub: ['医療・福祉施設給食製造作業'],
      },
    ],
  },

  // 5
  {
    id: '繊維・衣服関係',
    sub: [
      {
        id: '紡績運転',
        sub: [
          '前紡工程作業',
          '精紡工程作業',
          '巻糸工程作業',
          '合ねん糸工程作業',
        ],
      },
      {
        id: '織布運転',
        sub: ['準備工程作業', '製織工程作業', '仕上工程作業'],
      },
      {
        id: '染色',
        sub: ['糸浸染作業', '織物・ニット浸染作業'],
      },
      {
        id: 'ニット製品製造',
        sub: ['靴下製造作業', '丸編みニット製造作業'],
      },
      {
        id: 'たて編ニット生地製造',
        sub: ['たて編ニット生地製造作業'],
      },
      {
        id: '婦人子供服製造',
        sub: ['婦人子供既製服縫製作業'],
      },
      {
        id: '紳士服製造',
        sub: ['紳士既製服製造作業'],
      },
      {
        id: '下着類製造',
        sub: ['下着類製造作業'],
      },
      {
        id: '寝具製作',
        sub: ['寝具製作作業'],
      },
      {
        id: 'カーペット製造',
        sub: [
          '織じゅうたん製造作業',
          'タフテッドカーペット製造作業',
          'ニードルパンチカーペット製造作業',
        ],
      },
      {
        id: '帆布製品製造',
        sub: ['帆布製品製造作業'],
      },
      {
        id: '布はく縫製',
        sub: ['ワイシャツ製造作業'],
      },
      {
        id: '座席シート縫製',
        sub: ['自動車シート縫製作業'],
      },
    ],
  },

  // 6
  {
    id: '機械・金属関係',
    sub: [
      {
        id: '鋳造',
        sub: ['鋳鉄鋳物鋳造作業', '非鉄金属鋳物鋳造作業'],
      },
      {
        id: '鍛造',
        sub: ['ハンマ型鍛造作業', 'プレス型鍛造作業'],
      },
      {
        id: 'ダイカスト',
        sub: ['ホットチャンバダイカスト作業', 'コールドチャンバダイカスト作業'],
      },
      {
        id: '機械加工',
        sub: [
          '普通旋盤作業',
          'フライス盤作業',
          '数値制御旋盤作業',
          'マシニングセンタ作業',
        ],
      },
      {
        id: '金属プレス加工',
        sub: ['金属プレス作業'],
      },
      {
        id: '鉄工',
        sub: ['構造物鉄工作業'],
      },
      {
        id: '工場板金',
        sub: ['機械板金作業'],
      },
      {
        id: 'めっき',
        sub: ['電気めっき作業', '溶融亜鉛めっき作業'],
      },
      {
        id: 'アルミニウム陽極酸化処理',
        sub: ['陽極酸化処理作業'],
      },
      {
        id: '仕上げ',
        sub: ['治工具仕上げ作業', '金型仕上げ作業', '機械組立仕上げ作業'],
      },
      {
        id: '機械検査',
        sub: ['機械検査作業'],
      },
      {
        id: '機械保全',
        sub: ['機械系保全作業'],
      },
      {
        id: '電子機器組立て',
        sub: ['電子機器組立て作業'],
      },
      {
        id: '電気機器組立て',
        sub: [
          '回転電機組立て作業',
          '変圧器組立て作業',
          '配電盤・制御盤組立て作業',
          '開閉制御器具組立て作業',
          '回転電機巻線製作作業',
        ],
      },
      {
        id: 'プリント配線板製造',
        sub: ['プリント配線板設計作業', 'プリント配線板製造作業'],
      },
    ],
  },

  // 7
  {
    id: '空港グランドハンドリング',
    sub: [
      {
        id: '空港グランドハンドリング',
        sub: ['航空機地上支援作業', '航空貨物取扱作業', '客室清掃作業'],
      },
    ],
  },

  // 8
  {
    id: 'その他',
    sub: [
      {
        id: '家具製作',
        sub: ['家具手加工作業'],
      },
      {
        id: '印刷',
        sub: ['オフセット印刷作業'],
      },
      {
        id: '製本',
        sub: ['製本作業'],
      },
      {
        id: 'プラスチック成形',
        sub: [
          '圧縮成形作業',
          '射出成形作業',
          'インフレーション成形作業',
          'ブロー成形作業',
        ],
      },
      {
        id: '強化プラスチック成形',
        sub: ['手積み積層成形作業'],
      },
      {
        id: '塗装',
        sub: ['建築塗装作業', '金属塗装作業', '鋼橋塗装作業', '噴霧塗装作業'],
      },
      {
        id: '溶接',
        sub: ['手溶接', '半自動溶接'],
      },
      {
        id: '工業包装',
        sub: ['工業包装作業'],
      },
      {
        id: '紙器・段ボール箱製造',
        sub: [
          '印刷箱打抜き作業',
          '印刷箱製箱作業',
          '貼箱製造作業',
          '段ボール箱製造作業',
        ],
      },
      {
        id: '陶磁器工業製品製造',
        sub: ['機械ろくろ成形作業', '圧力鋳込み成形作業', 'パッド印刷作業'],
      },
      {
        id: '自動車整備',
        sub: ['自動車整備作業'],
      },
      {
        id: 'ビルクリーニング',
        sub: ['ビルクリーニング作業'],
      },
      {
        id: '介護',
        sub: ['介護作業'],
      },
      {
        id: 'リネンサプライ',
        sub: ['リネンサプライ仕上げ作業'],
      },
      {
        id: 'コンクリート製品製造',
        sub: ['コンクリート製品製造'],
      },
    ],
  },

  // 9
  // {
  //   id: "養殖業",
  //   sub: [
  //     {
  //       id: "",
  //       sub: [],
  //     },
  //   ],
  // },
]

export const bulan = [
  { value: 1, label: 'Januari' },
  { value: 2, label: 'Februari' },
  { value: 3, label: 'Maret' },
  { value: 4, label: 'April' },
  { value: 5, label: 'Mei' },
  { value: 6, label: 'Juni' },
  { value: 7, label: 'Juli' },
  { value: 8, label: 'Agustus' },
  { value: 9, label: 'September' },
  { value: 10, label: 'Oktober' },
  { value: 11, label: 'November' },
  { value: 12, label: 'Desember' },
]

export const tingkatanPendidikan = [
  { label: 'SD/Sederajat', value: 'sd' },
  { label: 'SMP/Sederajat', value: 'smp' },
  { label: 'SMA/Sederajat', value: 'sma' },
  { label: 'Diploma satu (D1)', value: 'd1' },
  { label: 'Diploma dua (D2)', value: 'd2' },
  { label: 'Diploma tiga (D3)', value: 'd3' },
  { label: 'Diploma empat (D4)', value: 'd4' },
  { label: 'Sarjana (S1)', value: 's1' },
  { label: 'Magister (S2)', value: 's2' },
  { label: 'Doktor (S3)', value: 's3' },
]

export const optionsSkill = [
  { id: 47, label: 'Accounting' },
  { id: 94, label: 'Airlines/Aviation' },
  { id: 120, label: 'Alternative Dispute Resolution' },
  { id: 125, label: 'Alternative Medicine' },
  { id: 127, label: 'Animation' },
  { id: 19, label: 'Apparel & Fashion' },
  { id: 50, label: 'Architecture & Planning' },
  { id: 111, label: 'Arts & Crafts' },
  { id: 53, label: 'Automotive' },
  { id: 52, label: 'Aviation & Aerospace' },
  { id: 41, label: 'Banking' },
  { id: 12, label: 'Biotechnology' },
  { id: 36, label: 'Broadcast Media' },
  { id: 49, label: 'Building Materials' },
  { id: 138, label: 'Business Supplies & Equipment' },
  { id: 129, label: 'Capital Markets' },
  { id: 54, label: 'Chemicals' },
  { id: 90, label: 'Civic & Social Organization' },
  { id: 51, label: 'Civil Engineering' },
  { id: 128, label: 'Commercial Real Estate' },
  { id: 118, label: 'Computer & Network Security' },
  { id: 109, label: 'Computer Games' },
  { id: 3, label: 'Computer Hardware' },
  { id: 5, label: 'Computer Networking' },
  { id: 4, label: 'Computer Software' },
  { id: 48, label: 'Construction' },
  { id: 24, label: 'Consumer Electronics' },
  { id: 25, label: 'Consumer Goods' },
  { id: 91, label: 'Consumer Services' },
  { id: 18, label: 'Cosmetics' },
  { id: 65, label: 'Dairy' },
  { id: 1, label: 'Defense & Space' },
  { id: 99, label: 'Design' },
  { id: 132, label: 'E-learning' },
  { id: 69, label: 'Education Management' },
  { id: 112, label: 'Electrical & Electronic Manufacturing' },
  { id: 28, label: 'Entertainment' },
  { id: 86, label: 'Environmental Services' },
  { id: 110, label: 'Events Services' },
  { id: 76, label: 'Executive Office' },
  { id: 122, label: 'Facilities Services' },
  { id: 63, label: 'Farming' },
  { id: 43, label: 'Financial Services' },
  { id: 38, label: 'Fine Art' },
  { id: 66, label: 'Fishery' },
  { id: 34, label: 'Food & Beverages' },
  { id: 23, label: 'Food Production' },
  { id: 101, label: 'Fundraising' },
  { id: 26, label: 'Furniture' },
  { id: 29, label: 'Gambling & Casinos' },
  { id: 145, label: 'Glass, Ceramics & Concrete' },
  { id: 75, label: 'Government Administration' },
  { id: 148, label: 'Government Relations' },
  { id: 140, label: 'Graphic Design' },
  { id: 124, label: 'Health, Wellness & Fitness' },
  { id: 68, label: 'Higher Education' },
  { id: 14, label: 'Hospital & Health Care' },
  { id: 31, label: 'Hospitality' },
  { id: 137, label: 'Human Resources' },
  { id: 134, label: 'Import & Export' },
  { id: 88, label: 'Individual & Family Services' },
  { id: 147, label: 'Industrial Automation' },
  { id: 84, label: 'Information Services' },
  { id: 96, label: 'Information Technology & Services' },
  { id: 42, label: 'Insurance' },
  { id: 74, label: 'International Affairs' },
  { id: 141, label: 'International Trade & Development' },
  { id: 6, label: 'Internet' },
  { id: 45, label: 'Investment Banking' },
  { id: 46, label: 'Investment Management' },
  { id: 73, label: 'Judiciary' },
  { id: 77, label: 'Law Enforcement' },
  { id: 9, label: 'Law Practice' },
  { id: 10, label: 'Legal Services' },
  { id: 72, label: 'Legislative Office' },
  { id: 30, label: 'Leisure, Travel & Tourism' },
  { id: 85, label: 'Libraries' },
  { id: 116, label: 'Logistics & Supply Chain' },
  { id: 143, label: 'Luxury Goods & Jewelry' },
  { id: 55, label: 'Machinery' },
  { id: 11, label: 'Management Consulting' },
  { id: 95, label: 'Maritime' },
  { id: 97, label: 'Market Research' },
  { id: 80, label: 'Marketing & Advertising' },
  { id: 135, label: 'Mechanical Or Industrial Engineering' },
  { id: 126, label: 'Media Production' },
  { id: 17, label: 'Medical Device' },
  { id: 13, label: 'Medical Practice' },
  { id: 139, label: 'Mental Health Care' },
  { id: 71, label: 'Military' },
  { id: 56, label: 'Mining & Metals' },
  { id: 3131, label: 'Mobile Games' },
  { id: 35, label: 'Motion Pictures & Film' },
  { id: 37, label: 'Museums & Institutions' },
  { id: 115, label: 'Music' },
  { id: 114, label: 'Nanotechnology' },
  { id: 81, label: 'Newspapers' },
  { id: 100, label: 'Non-profit Organization Management' },
  { id: 57, label: 'Oil & Energy' },
  { id: 113, label: 'Online Media' },
  { id: 123, label: 'Outsourcing/Offshoring' },
  { id: 87, label: 'Package/Freight Delivery' },
  { id: 146, label: 'Packaging & Containers' },
  { id: 61, label: 'Paper & Forest Products' },
  { id: 39, label: 'Performing Arts' },
  { id: 15, label: 'Pharmaceuticals' },
  { id: 131, label: 'Philanthropy' },
  { id: 136, label: 'Photography' },
  { id: 117, label: 'Plastics' },
  { id: 107, label: 'Political Organization' },
  { id: 67, label: 'Primary/Secondary Education' },
  { id: 83, label: 'Printing' },
  { id: 105, label: 'Professional Training & Coaching' },
  { id: 102, label: 'Program Development' },
  { id: 79, label: 'Public Policy' },
  { id: 98, label: 'Public Relations & Communications' },
  { id: 78, label: 'Public Safety' },
  { id: 82, label: 'Publishing' },
  { id: 62, label: 'Railroad Manufacture' },
  { id: 64, label: 'Ranching' },
  { id: 44, label: 'Real Estate' },
  { id: 40, label: 'Recreational Facilities & Services' },
  { id: 89, label: 'Religious Institutions' },
  { id: 144, label: 'Renewables & Environment' },
  { id: 70, label: 'Research' },
  { id: 32, label: 'Restaurants' },
  { id: 27, label: 'Retail' },
  { id: 121, label: 'Security & Investigations' },
  { id: 7, label: 'Semiconductors' },
  { id: 58, label: 'Shipbuilding' },
  { id: 20, label: 'Sporting Goods' },
  { id: 33, label: 'Sports' },
  { id: 104, label: 'Staffing & Recruiting' },
  { id: 22, label: 'Supermarkets' },
  { id: 8, label: 'Telecommunications' },
  { id: 60, label: 'Textiles' },
  { id: 130, label: 'Think Tanks' },
  { id: 21, label: 'Tobacco' },
  { id: 108, label: 'Translation & Localization' },
  { id: 92, label: 'Transportation/Trucking/Railroad' },
  { id: 59, label: 'Utilities' },
  { id: 106, label: 'Venture Capital & Private Equity' },
  { id: 16, label: 'Veterinary' },
  { id: 93, label: 'Warehousing' },
  { id: 133, label: 'Wholesale' },
  { id: 142, label: 'Wine & Spirits' },
  { id: 119, label: 'Wireless' },
  { id: 103, label: 'Writing & Editing' },
  { id: 9999, label: 'Lainnya' },
]

export const workExperienceIndustries = optionsSkill
