import React, { useState, useEffect, useContext, createContext } from "react"
import { produce } from "immer"
import cuid from "cuid"

import { fetcher } from "../utils/fetcher"
import { useApplicationQuery } from "../atoms/applicationAtom"

const VisaContext = createContext()
export const useVisa = () => useContext(VisaContext)

export const VisaProvider = ({ children }) => {
  const { data: application } = useApplicationQuery()

  const [document, setDocument] = useState(null)
  useEffect(() => {
    if (application) {
      setDocument(application?.document_visa)
    }
  }, [application])

  const [medicalCheckupFile, setMedicalCheckupFile] = useState([])
  useEffect(() => {
    if (medicalCheckupFile) {
      updateDocument()
    }
  }, [medicalCheckupFile])

  const [photoFile, setPhotoFile] = useState([])
  useEffect(() => {
    if (photoFile) {
      updateDocument()
    }
  }, [photoFile])

  const [sswFile, setSswFile] = useState([])
  useEffect(() => {
    if (sswFile) {
      updateDocument()
    }
  }, [sswFile])

  const [titpFile, setTitpFile] = useState([])
  useEffect(() => {
    if (titpFile) {
      updateDocument()
    }
  }, [titpFile])

  const [bahasaFile, setBahasaFile] = useState([])
  useEffect(() => {
    if (bahasaFile) {
      updateDocument()
    }
  }, [bahasaFile])

  const [penilaianFile, setPenilaianFile] = useState([])
  useEffect(() => {
    if (penilaianFile) {
      updateDocument()
    }
  }, [penilaianFile])

  const [pemeriksaanKesehatanFile, setPemeriksaanKesehatanFile] = useState([])
  useEffect(() => {
    if (pemeriksaanKesehatanFile) {
      updateDocument()
    }
  }, [pemeriksaanKesehatanFile])

  const [resumeMagangFile, setResumeMagangFile] = useState([])
  useEffect(() => {
    if (resumeMagangFile) {
      updateDocument()
    }
  }, [resumeMagangFile])

  const [ktklnFile, setKtklnFile] = useState([])
  useEffect(() => {
    if (ktklnFile) {
      updateDocument()
    }
  }, [ktklnFile])

  const [formulirVisaFile, setFormulirVisaFile] = useState([])
  useEffect(() => {
    if (formulirVisaFile) {
      updateDocument()
    }
  }, [formulirVisaFile])

  const [coeOriginalFile, setCoeOriginalFile] = useState([])
  useEffect(() => {
    if (coeOriginalFile) {
      updateDocument()
    }
  }, [coeOriginalFile])

  const [secondMedicalCheckupFile, setSecondMedicalCheckupFile] = useState([])
  useEffect(() => {
    if (secondMedicalCheckupFile) {
      updateDocument()
    }
  }, [secondMedicalCheckupFile])

  const [perjanjianFile, setPerjanjianFile] = useState([])
  useEffect(() => {
    if (perjanjianFile) {
      updateDocument()
    }
  }, [perjanjianFile])

  const [syaratKerjaFile, setSyaratKerjaFile] = useState([])
  useEffect(() => {
    if (syaratKerjaFile) {
      updateDocument()
    }
  }, [syaratKerjaFile])

  const [rencanaBantuanFile, setRencanaBantuanFile] = useState([])
  useEffect(() => {
    if (rencanaBantuanFile) {
      updateDocument()
    }
  }, [rencanaBantuanFile])

  const [konfirmasiPanduanFile, setKonfirmasiPanduanFile] = useState([])
  useEffect(() => {
    if (konfirmasiPanduanFile) {
      updateDocument()
    }
  }, [konfirmasiPanduanFile])

  const [detailRekrutmenFile, setDetailRekrutmenFile] = useState([])
  useEffect(() => {
    if (detailRekrutmenFile) {
      updateDocument()
    }
  }, [detailRekrutmenFile])

  const [persetujuanPembayaranBiayaFile, setPersetujuanPembayaranBiayaFile] =
    useState([])
  useEffect(() => {
    if (persetujuanPembayaranBiayaFile) {
      updateDocument()
    }
  }, [persetujuanPembayaranBiayaFile])

  const [cvFile, setCvFile] = useState([])
  useEffect(() => {
    if (cvFile) {
      updateDocument()
    }
  }, [cvFile])

  const [laporanPenerimaanFile, setLaporanPenerimaanFile] = useState([])
  useEffect(() => {
    if (laporanPenerimaanFile) {
      updateDocument()
    }
  }, [laporanPenerimaanFile])

  useEffect(() => {
    if (document) {
      setMedicalCheckupFile(document?.medical_checkup ?? [])
      setPhotoFile(document?.photo ?? [])
      setSswFile(document?.ssw_certificates ?? [])
      setTitpFile(document?.titp_certificates ?? [])
      setBahasaFile(document?.bahasa_certificates ?? [])
      setPenilaianFile(document?.penilaian_magang ?? [])
      setPemeriksaanKesehatanFile(document?.pemeriksaan_kesehatan ?? [])
      setResumeMagangFile(document?.resume_magang ?? [])
      setKtklnFile(document?.ktkln ?? [])
      setFormulirVisaFile(document?.formulir_visa ?? [])
      setCoeOriginalFile(document?.coe_original ?? [])
      setSecondMedicalCheckupFile(document?.second_medical_checkup ?? [])
      setPerjanjianFile(document?.perjanjian ?? [])
      setSyaratKerjaFile(document?.syarat_kerja ?? [])
      setRencanaBantuanFile(document?.rencana_bantuan ?? [])
      setKonfirmasiPanduanFile(document?.konfirmasi_panduan ?? [])
      setDetailRekrutmenFile(document?.detail_rekrutmen ?? [])
      setPersetujuanPembayaranBiayaFile(
        document?.persetujuan_pembayaran_biaya ?? []
      )
      setCvFile(document?.cv ?? [])
      setLaporanPenerimaanFile(document?.laporan_penerimaan ?? [])
    }
  }, [document])

  const updateDocument = async () => {
    if (application?._id) {
      await handleUpdateDocument(
        {
          document_visa: {
            medical_checkup: medicalCheckupFile,
            photo: photoFile,
            ssw_certificates: sswFile,
            titp_certificates: titpFile,
            bahasa_certificates: bahasaFile,
            penilaian_magang: penilaianFile,
            pemeriksaan_kesehatan: pemeriksaanKesehatanFile,
            resume_magang: resumeMagangFile,
            ktkln: ktklnFile,
            formulir_visa: formulirVisaFile,
            coe_original: coeOriginalFile,
            second_medical_checkup: secondMedicalCheckupFile,

            perjanjian: perjanjianFile,
            syarat_kerja: syaratKerjaFile,
            rencana_bantuan: rencanaBantuanFile,
            konfirmasi_panduan: konfirmasiPanduanFile,
            detail_rekrutmen: detailRekrutmenFile,
            persetujuan_pembayaran_biaya: persetujuanPembayaranBiayaFile,
            cv: cvFile,
            laporan_penerimaan: laporanPenerimaanFile,
          },
        },
        application?._id
      )
    }
  }

  const handleUpdateDocument = async (data, id, callback) => {
    console.log({ DOCUMENT: id })
    await fetcher({
      method: "PUT",
      url: `/admin/update_application/${id}`,
      data,
    })
      .then((res) => {
        if (res?.data?.status === "success") {
          console.log("VISA UPDATED")
          if (callback && typeof callback === "function") {
            callback()
          }
        }
      })
      .catch((error) => {
        console.log({ FAILED_UPDATING_VISA: error })
      })
  }

  // 0 = Document gathering
  const handleSentToJapan = async (id, history = [], array, setter) => {
    let newHistory = produce(history, (draft) => {
      draft.unshift({
        message_id: "Dokumen dalam perjalanan ke Jepang",
        message_jp: "必要書類をインドネシアに送付中です。",
        message_en: "Documents on the way to Japan",
        updated_at: Date.now(),
      })
    })

    let callback = () => {
      setter(
        produce(array, (draft) => {
          draft.visa_history = newHistory
          draft.visa_status = "1"
        })
      )
    }

    await handleUpdateDocument(
      { visa_status: "1", visa_history: newHistory },
      id,
      callback
    )
  }

  const handleArriveAtJapan = async (id, history = [], array, setter) => {
    let newHistory = produce(history, (draft) => {
      draft.unshift({
        message_id: "Dokumen tiba di Jepang",
        message_jp: "必要書類が日本に到着しました。",
        message_en: "Arrived at Japan",
        updated_at: Date.now(),
      })
    })

    let callback = () => {
      setter(
        produce(array, (draft) => {
          draft.visa_history = newHistory
          draft.visa_status = "2"
        })
      )
    }

    await handleUpdateDocument(
      { visa_status: "2", visa_history: newHistory },
      id,
      callback
    )
  }

  const handleSentToMoj = async (id, history = [], array, setter) => {
    let newHistory = produce(history, (draft) => {
      draft.unshift({
        message_id: "Dokumen telah dikirimkan ke MoJ",
        message_jp: "必要書類が日本大使館に提出されました。",
        message_en: "Documents submitted to MoJ",
        updated_at: Date.now(),
      })
    })

    let callback = () => {
      setter(
        produce(array, (draft) => {
          draft.visa_history = newHistory
          draft.visa_status = "3"
        })
      )
    }

    await handleUpdateDocument(
      { visa_status: "3", visa_history: newHistory },
      id,
      callback
    )
  }

  const handleCoeReleased = async (id, history = [], array, setter) => {
    let newHistory = produce(history, (draft) => {
      draft.unshift({
        message_id: "CoE telah diterbitkan",
        message_jp: "CoEが発行されました。",
        message_en: "CoE released",
        updated_at: Date.now(),
      })
    })

    let callback = () => {
      setter(
        produce(array, (draft) => {
          draft.visa_history = newHistory
          draft.visa_status = "4"
        })
      )
    }

    await handleUpdateDocument(
      { visa_status: "4", visa_history: newHistory },
      id,
      callback
    )
  }

  const handleSentToIndonesia = async (id, history = [], array, setter) => {
    let newHistory = produce(history, (draft) => {
      draft.unshift({
        message_id: "Dokumen dalam perjalanan ke Indonesia",
        message_jp: "必要書類をインドネシアに送付中です。",
        message_en: "Documents on the way to Indonesia",
        updated_at: Date.now(),
      })
    })

    let callback = () => {
      setter(
        produce(array, (draft) => {
          draft.visa_history = newHistory
          draft.visa_status = "5"
        })
      )
    }

    await handleUpdateDocument(
      { visa_status: "5", visa_history: newHistory },
      id,
      callback
    )
  }

  const handleArrivedAtIndonesia = async (id, history = [], array, setter) => {
    let newHistory = produce(history, (draft) => {
      draft.unshift({
        message_id: "Dokumen tiba di Indonesia",
        message_jp: "必要書類がインドネシアに到着しました。",
        message_en: "Documents arrived at Indonesia",
        updated_at: Date.now(),
      })
    })

    let callback = () => {
      setter(
        produce(array, (draft) => {
          draft.visa_history = newHistory
          draft.visa_status = "6"
        })
      )
    }

    await handleUpdateDocument(
      { visa_status: "6", visa_history: newHistory },
      id,
      callback
    )
  }

  const handleVisaCompleted = async (id, history = [], array, setter) => {
    let newHistory = produce(history, (draft) => {
      draft.unshift({
        message_id: "Pengurusan VISA selesai",
        message_jp: "VISA取得プロセスが完了しました！",
        message_en: "VISA process completed!",
        updated_at: Date.now(),
      })
    })

    let callback = () => {
      setter(
        produce(array, (draft) => {
          draft.visa_history = newHistory
          draft.visa_status = "7"
        })
      )
    }

    await handleUpdateDocument(
      { visa_status: "7", visa_history: newHistory },
      id,
      callback
    )
  }

  const addVisaFile = (item, setter, array) => {
    console.log("ADDING FILE...")
    setter(
      produce(array, (draft) => {
        draft.push({
          ...item,
          id: cuid(),
          created_at: Date.now(),
        })
      })
    )
  }

  const removeVisaFile = (id, setter, array) => {
    console.log("DELETING FILE...")
    let index = array?.findIndex((item) => item?.id === id)
    console.log({ index })
    if (index !== -1) {
      setter(
        produce(array, (draft) => {
          draft.splice(index, 1)
        })
      )
    }
  }

  const editVisaFile = (id, data, setter, array) => {
    let index = array?.findIndex((item) => item?.id === id)
    if (index !== -1) {
      setter(
        produce(array, (draft) => {
          draft[index] = {
            ...array[index],
            ...data,
          }
        })
      )
    }
  }

  return (
    <VisaContext.Provider
      value={{
        medicalCheckupFile,
        setMedicalCheckupFile,
        photoFile,
        setPhotoFile,
        sswFile,
        setSswFile,
        titpFile,
        setTitpFile,
        bahasaFile,
        setBahasaFile,
        penilaianFile,
        setPenilaianFile,
        pemeriksaanKesehatanFile,
        setPemeriksaanKesehatanFile,

        resumeMagangFile,
        setResumeMagangFile,

        ktklnFile,
        setKtklnFile,
        formulirVisaFile,
        setFormulirVisaFile,
        coeOriginalFile,
        setCoeOriginalFile,
        secondMedicalCheckupFile,
        setSecondMedicalCheckupFile,

        perjanjianFile,
        setPerjanjianFile,
        syaratKerjaFile,
        setSyaratKerjaFile,
        rencanaBantuanFile,
        setRencanaBantuanFile,
        konfirmasiPanduanFile,
        setKonfirmasiPanduanFile,
        detailRekrutmenFile,
        setDetailRekrutmenFile,

        persetujuanPembayaranBiayaFile,
        setPersetujuanPembayaranBiayaFile,
        cvFile,
        setCvFile,
        laporanPenerimaanFile,
        setLaporanPenerimaanFile,

        addVisaFile,
        editVisaFile,
        removeVisaFile,

        handleSentToJapan,
        handleArriveAtJapan,
        handleSentToMoj,
        handleCoeReleased,
        handleSentToIndonesia,
        handleArrivedAtIndonesia,
        handleVisaCompleted,

        handleUpdateDocument,
      }}
    >
      {children}
    </VisaContext.Provider>
  )
}
