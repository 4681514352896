import { useAtom, useAtomValue, useSetAtom } from 'jotai'
import React, { useEffect, useState } from 'react'
import { educationAtom, educationsAtom } from '../atoms/educationAtom'
import { useUserQuery } from '../atoms/userAtom'
import { bulan } from '../utils/data'

export const useEducation = () => {
  const { data: me } = useUserQuery()

  const setEducations = useSetAtom(educationsAtom)
  const education = useAtomValue(educationAtom)

  useEffect(() => {
    if (me?.education) {
      setEducations(me?.education)
    }
  }, [me])

  const [degree, setDegree] = useState('s1')
  const [major, setMajor] = useState('')
  const [graduationMonth, setGraduationMonth] = useState(bulan[0])
  const [graduationYear, setGraduationYear] = useState('')
  const [school, setSchool] = useState('')
  const [diploma, setDiploma] = useState('')

  useEffect(() => {
    if (education) {
      setDegree(education?.degree)
      setMajor(education?.major)
      setGraduationMonth(education?.graduationMonth)
      setGraduationYear(education?.graduationYear)
      setSchool(education?.school)
      setDiploma(education?.diploma)
    }
  }, [education])

  return {
    degree,
    setDegree,
    major,
    setMajor,
    graduationMonth,
    setGraduationMonth,
    graduationYear,
    setGraduationYear,
    school,
    setSchool,
    diploma,
    setDiploma,
  }
}
