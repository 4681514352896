import produce from 'immer'
import { atom } from 'jotai'

import { event } from '../utils/event'
import { userAtom } from './userAtom'

export const chatChannelUrlAtom = atom(
  process.env.NEXT_PUBLIC_SOCKET_HOST + '/chat'
)

export const socketChatAtom = atom(null)
export const chatRoomAtom = atom(null)
export const activeChatRoomIdAtom = atom((get) => get(chatRoomAtom)?.roomId)

export const unreadChatsAtom = atom([])
export const addUnreadChatAtom = atom(null, (_get, set, unreadChat) => {
  let updatedData = [unreadChat, ..._get(unreadChatsAtom)]
  set(unreadChatsAtom, updatedData)
})
export const createUnreadChatsAtom = atom(
  null,
  (_get, set, { roomId, chat }) => {
    const me = _get(userAtom)
    const socketChat = _get(socketChatAtom)

    if (chat?.sender?._id !== me?._id) {
      socketChat.emit(
        event.chat.createUnread,
        {
          room: roomId,
          chat: chat?._id,
          user: me?._id,
          role: me?.role,
        },
        (response) => {
          let updatedData = [response, ..._get(unreadChatsAtom)]
          set(unreadChatsAtom, updatedData)
        }
      )
    }
  }
)
export const removeUnreadChatAtom = atom(
  null,
  (_get, set, { roomId, chatId }) => {
    const me = _get(userAtom)
    const socketChat = _get(socketChatAtom)
    const unreadChats = _get(unreadChatsAtom)

    let index = unreadChats.findIndex((c) => c?.chatId === chatId)
    if (index !== -1) {
      socketChat.emit(
        event.chat.deleteUnread,
        {
          roomId,
          chatId,
          userId: me?._id,
        },
        (response) => {
          let updatedData = produce(_get(unreadChatsAtom), (draft) => {
            let index = draft.findIndex((d) => d?.chatId === response?.chatId)
            if (index !== -1) {
              draft.splice(index, 1)
            }
          })
          set(unreadChatsAtom, updatedData)
        }
      )
    }
  }
)
export const updateUnreadChatsAtom = atom(
  null,
  (_get, set, { roomId, chat }) => {
    let newData = { ..._get(unreadChatsAtom) }
    newData[roomId] = chat
    set(unreadChatsAtom, newData)
  }
)

export const chatHistoryAtom = atom([])
export const updateChatHistoryAtom = atom(null, (get, set, chat) => {
  if (get(activeChatRoomIdAtom) === chat?.roomId) {
    set(chatHistoryAtom, [...get(chatHistoryAtom), chat])
  }
})
export const fetchChatHistoryAtom = atom(null, (get, set, id) => {
  const socketChat = get(socketChatAtom)
  const roomId = id ? id : get(activeChatRoomIdAtom)

  socketChat.emit(event.room.getChats, { roomId }, (history) => {
    console.log({ fetch_chats: history })

    if (history) {
      set(chatHistoryAtom, history)
    }
  })
})

export const sendChatAtom = atom(
  null,
  (get, set, { body, onSuccess, onError }) => {
    const me = get(userAtom)
    const socketChat = get(socketChatAtom)
    const roomId = get(activeChatRoomIdAtom)
    const room = get(chatRoomAtom)

    socketChat.emit(
      event.room.newChat,
      {
        roomId,
        category: 'text',
        body,
        sender: me?._id,
        role: me?.role,
        roomType: room?.roomType,
        room: {
          title: room?.meta?.title,
          image: room?.meta?.image,
        },
      },
      (chat) => {
        if (chat) {
          if (onSuccess && typeof onSuccess === 'function') {
            onSuccess(chat)
          }
          // set(chatHistoryAtom, [chat, ...get(chatHistoryAtom)])
        }
      }
    )
  }
)

export const chatRoomParticipantsAtom = atom([])
export const updateRoomParticipantsAtom = atom(
  null,
  (_get, set, { roomId, users }) => {
    console.log('Someone entering the room...')
    set(chatRoomParticipantsAtom, users)
  }
)
export const enterChatRoomAtom = atom(null, (get, set, id) => {
  const me = get(userAtom)
  const socketChat = get(socketChatAtom)
  const roomId = id ? id : get(activeChatRoomIdAtom)

  socketChat.emit(event.room.enter, {
    roomId,
    user: { _id: me?._id, role: me?.role, name: me?.name, photo: me?.photo },
  })
})
export const leaveChatRoomAtom = atom(null, (get, set, id) => {
  const me = get(userAtom)
  const socketChat = get(socketChatAtom)
  const roomId = id ? id : get(activeChatRoomIdAtom)

  socketChat.emit(event.room.leave, {
    roomId,
    user: { _id: me?._id, role: me?.role, name: me?.name, photo: me?.photo },
  })
})

export const isTypingAtom = atom({})
export const updateTypersAtom = atom(null, (_get, set, { roomId, users }) => {
  const me = _get(userAtom)
  const processedUsers = produce(users, (draft) => {
    let index = draft.findIndex((d) => d?._id === me?._id)
    if (index !== -1) {
      draft.splice(index, 1)
    }
  })
  let newData = { ..._get(isTypingAtom) }
  newData[roomId] = processedUsers
  set(isTypingAtom, newData)
})

export const startTypingAtom = atom(null, (get, set, id) => {
  const me = get(userAtom)
  const socketChat = get(socketChatAtom)
  const roomId = id ? id : get(activeChatRoomIdAtom)

  socketChat.emit(event.room.startTyping, {
    roomId,
    user: { _id: me?._id, role: me?.role, name: me?.name, photo: me?.photo },
  })
})
export const stopTypingAtom = atom(null, (get, set, id) => {
  const me = get(userAtom)
  const socketChat = get(socketChatAtom)
  const roomId = id ? id : get(activeChatRoomIdAtom)

  socketChat.emit(event.room.stopTyping, {
    roomId,
    user: { _id: me?._id, role: me?.role, name: me?.name, photo: me?.photo },
  })
})
