import React, { useEffect } from "react"

import { SocketChatChannel } from "../hooks/useSocket"
import { VisaProvider } from "./../hooks"

const AppProvider = React.memo(({ children }) => {
  return (
    <>
      <SocketChatChannel />
      <VisaProvider>{children}</VisaProvider>
    </>
  )
})

AppProvider.displayName = "AppProvider"

export default AppProvider
