import React, { useEffect, useRef } from "react"
import { useAtomValue, useSetAtom } from "jotai"
import { io } from "socket.io-client"

import { event } from "../utils/event"

import { apiTokenAtom, useUserQuery } from "../atoms/userAtom"

import {
  chatChannelUrlAtom,
  chatRoomAtom,
  socketChatAtom,
  unreadChatsAtom,
  addUnreadChatAtom,
  updateChatHistoryAtom,
  updateRoomParticipantsAtom,
  updateTypersAtom,
} from "../atoms/chatAtom"
import ClientWrapper from "../utils/ClientWrapper"
import {
  notificationChannelUrlAtom,
  notificationRoomAtom,
  notificationsAtom,
  socketNotificationAtom,
} from "../atoms/notificationAtom"
import { useSession } from "next-auth/react"

export const SocketChatChannel = ClientWrapper(
  React.memo(() => {
    const ioRef = useRef(null)
    const notifRef = useRef(null)

    const { data: session, status } = useSession()
    const { data: me } = useUserQuery()

    const apiToken = useAtomValue(apiTokenAtom)

    const chatChannelUrl = useAtomValue(chatChannelUrlAtom)
    const setSocketChat = useSetAtom(socketChatAtom)
    const setChatRoom = useSetAtom(chatRoomAtom)

    const updateChatHistory = useSetAtom(updateChatHistoryAtom)
    const setUnreadChats = useSetAtom(unreadChatsAtom)
    const addUnreadChat = useSetAtom(addUnreadChatAtom)
    const updateRoomParticipants = useSetAtom(updateRoomParticipantsAtom)
    const updateTypers = useSetAtom(updateTypersAtom)

    const notificationChannelUrl = useAtomValue(notificationChannelUrlAtom)
    const setSocketNotification = useSetAtom(socketNotificationAtom)
    const setNotifications = useSetAtom(notificationsAtom)

    useEffect(() => {
      if (status === "unauthenticated" || status === "loading") {
        if (ioRef?.current && !session) {
          console.log("Disconnect!")
          ioRef.current.disconnect()
          return
        }

        return
      }

      if (!apiToken || ioRef?.current || !me?._id || !chatChannelUrl) {
        return
      }

      const socketChat = io(chatChannelUrl, {
        query: {
          userId: me?._id,
          role: me?.role ?? "candidate",
          apiToken,
        },
      })

      if (socketChat?.disconnected) {
        socketChat?.connect()
      }

      const socketNotif = io(notificationChannelUrl, {
        query: {
          userId: me?._id,
          role: me?.role ?? "pic",
          apiToken,
        },
      })

      if (socketNotif?.disconnected) {
        socketNotif?.connect()
      }

      ioRef.current = socketChat
      setSocketChat(socketChat)
      window.ioChat = socketChat

      notifRef.current = socketNotif
      setSocketNotification(socketNotif)
      window.ioNotif = socketNotif

      socketChat.on(event.own, (data) => {
        if (data?.roomId === me?._id) {
          console.log({ self: data })
        }
      })

      // Connected user entering a room
      socketChat.on(event.room.enter, ({ roomId, users }) => {
        updateRoomParticipants({ roomId, users })
      })
      // Connected user leaving a room
      socketChat.on(event.room.leave, ({ roomId, users }) => {
        updateRoomParticipants({ roomId, users })
      })

      socketChat.on(event.room.startTyping, ({ roomId, users }) => {
        updateTypers({ roomId, users })
      })
      socketChat.on(event.room.stopTyping, ({ roomId, users }) => {
        updateTypers({ roomId, users })
      })

      socketChat.on(event.room.newChat, (newChat) => {
        // Add to chat history
        // Add to unread chat if it's from another sender
        updateChatHistory(newChat)
        // console.log({ newChat })
        if (me?._id !== newChat?.sender?._id) {
          addUnreadChat({
            roomId: newChat?.roomId,
            chatId: newChat?._id,
            userId: me?._id,
            roomType: newChat?.roomType,
            created_at: newChat?.created_at,
            updated_at: newChat?.updated_at,
          })
        }
      })

      socketChat.on(event.room.update, (room) => setChatRoom(room))

      socketChat.on(event.room.refetch, () => {
        console.log("refetching...")
        socketChat.emit(
          event.greet,
          { roomId: me?._id },
          ({ rooms, unreadChats }) => {
            if (rooms) {
              // console.log({ rooms })
              setChatRoom(rooms)
            }
            if (unreadChats) {
              setUnreadChats(unreadChats)
            }
          }
        )
      })

      // Admin connected to chat channel
      socketChat.on("connect", () => {
        console.log("Connected to chat socket...")
        window.socketChat = socketChat
        socketChat.emit(
          event.greet,
          { roomId: me?._id },
          ({ rooms, unreadChats }) => {
            if (rooms) {
              console.log({ rooms })
              setChatRoom(rooms?.[0])
            }
            if (unreadChats) {
              setUnreadChats(unreadChats)
            }
          }
        )
      })

      socketChat.on("disconnect", () => {
        console.log("socketChat disconnected...")
      })

      socketChat.io.on("connect_error", (error) => {
        console.log({ socketChatConnectError: error })
      })

      socketChat.io.on("error", (error) => {
        console.log({ socketChatError: error })
      })

      socketNotif.on("connect", (data) => {
        window.socketNotif = socketNotif
        socketNotif.emit(
          event.greet,
          { roomId: me?._id },
          ({ notifications }) => {
            setNotifications(notifications)
          }
        )
      })

      socketNotif.on("disconnect", () => {
        console.log("socketNotif disconnected...")
      })

      socketNotif.io.on("connect_error", (error) => {
        console.log({ socketNotifConnectError: error })
      })

      socketNotif.io.on("error", (error) => {
        console.log({ socketNotifError: error })
      })

      socketNotif.on(event.notification.new, ({ notification }) => {
        console.log({ notification })
      })
    }, [
      apiToken,
      me,
      session,
      status,
      chatChannelUrlAtom,
      notificationChannelUrl,
    ])

    return null
  })
)
