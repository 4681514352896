import React, { useEffect, useState } from 'react'

const ClientWrapper = (OriginalComponent) => {
  const WrappedComponent = React.forwardRef((props, forwardedRef) => {
    const [hasMounted, setHasMounted] = useState(false)

    useEffect(() => {
      setHasMounted(true)
    }, [])

    if (!hasMounted) {
      return null
    }

    return <OriginalComponent ref={forwardedRef} {...props} />
  })

  return WrappedComponent
}

export default ClientWrapper
