import React, { useContext, createContext, useEffect, useState } from 'react'
import { produce } from 'immer'
import { useSetAtom } from 'jotai'

import { bulan } from '../utils/data'
import { epaIndustries } from '../atoms/setupAtom'

export const useEpa = (initial) => {
  const [hospital, setHospital] = useState(initial?.hospital ?? '')
  const [industry, setIndustry] = useState(initial?.industry ?? '')
  const [startMonth, setStartMonth] = useState(initial?.start_month ?? bulan[0])
  const [startYear, setStartYear] = useState(initial?.start_year ?? '')
  const [endMonth, setEndMonth] = useState(initial?.end_month ?? bulan[0])
  const [endYear, setEndYear] = useState(initial?.end_year ?? '')
  const [description, setDescription] = useState(initial?.description ?? '')

  useEffect(() => {
    if (!industry && epaIndustries?.length > 0) {
      setIndustry(epaIndustries?.[0].slug)
    }
  }, [industry, epaIndustries])

  const [certificateTest, setCertificateTest] = useState(
    initial?.certificate_test ?? ''
  )
  const [verifiedTest, setVerifiedTest] = useState(initial?.verified_test ?? '')
  const handleUpdateTest = (url) => {
    setCertificateTest(url)
    setVerifiedTest(false)
  }

  const [certificateProgram, setCertificateProgram] = useState(
    initial?.certificate_program ?? ''
  )
  const [verifiedProgram, setVerifiedProgram] = useState(
    initial?.verified_program ?? ''
  )

  const handleUpdateProgram = (url) => {
    setCertificateProgram(url)
    setVerifiedProgram(false)
  }

  const [data, setData] = useState()

  useEffect(() => {
    setData({
      ...initial,
      hospital,
      industry,
      start_month: startMonth,
      start_year: startYear,
      end_month: endMonth,
      end_year: endYear,
      description,
      certificate_test: certificateTest,
      verified_test: verifiedTest,
      certificate_program: certificateProgram,
      verified_program: verifiedProgram,
    })
  }, [
    hospital,
    industry,
    startMonth,
    startYear,
    endMonth,
    endYear,
    description,
    certificateTest,
    verifiedTest,
    certificateProgram,
    verifiedProgram,
  ])

  return {
    data,
    handleUpdateProgram,
    handleUpdateTest,

    hospital,
    setHospital,
    industry,
    setIndustry,
    startMonth,
    setStartMonth,
    startYear,
    setStartYear,
    endMonth,
    setEndMonth,
    endYear,
    setEndYear,
    description,
    setDescription,
    certificateTest,
    setCertificateTest,
    verifiedTest,
    setVerifiedTest,
    certificateProgram,
    setCertificateProgram,
    verifiedProgram,
    setVerifiedProgram,
  }
}
