import React, { useEffect, useState } from 'react'

import { bulan } from '../utils/data'

export const useTitp = (inital) => {
  const [status, setStatus] = useState(inital?.status ?? '1')
  const [company, setCompany] = useState(inital?.company ?? '')
  const [industry, setIndustry] = useState(inital?.industry ?? '')
  const [occupation, setOccupation] = useState(inital?.occupation ?? '')
  const [startMonth, setStartMonth] = useState(inital?.start_month ?? bulan[0])
  const [startYear, setStartYear] = useState(inital?.start_year ?? '')
  const [endMonth, setEndMonth] = useState(inital?.end_month ?? bulan[0])
  const [endYear, setEndYear] = useState(inital?.end_year ?? '')
  const [so, setSo] = useState(inital?.so ?? '')
  const [description, setDescription] = useState(inital?.description ?? '')

  const [certificate, setCertificate] = useState(inital?.certificate ?? '')
  const [verifiedCertificate, setVerifiedCertificate] = useState(
    inital?.verified_certificate
  )

  const handleUpdateCertificate = (url) => {
    setCertificate(url)
    setVerifiedCertificate(false)
  }

  const [resume, setResume] = useState(inital?.resume ?? '')
  const [verifiedResume, setVerifiedResume] = useState(inital?.verified_resume)

  const [additional, setAdditional] = useState(inital?.additional ?? '')
  const [verifiedAdditional, setVerifiedAddverifiedAdditional] = useState(
    inital?.verified_additional
  )
  const handleUpdateAdditional = (url) => {
    setAdditional(url)
    setVerifiedAddverifiedAdditional(false)
  }

  const [data, setData] = useState({})

  useEffect(() => {
    setData({
      status,
      company,
      industry,
      occupation,
      start_month: startMonth,
      start_year: startYear,
      end_month: endMonth,
      end_year: endYear,
      so,
      description,
      certificate,
      verified_certificate: verifiedCertificate,
      resume,
      verified_resume: verifiedResume,
      additional,
      verified_additional: verifiedAdditional,
    })
  }, [
    status,
    company,
    industry,
    occupation,
    startMonth,
    startYear,
    endMonth,
    endYear,
    so,
    description,
    certificate,
    verifiedCertificate,
    resume,
    verifiedResume,
    additional,
    verifiedAdditional,
  ])

  return {
    status,
    setStatus,
    company,
    setCompany,
    industry,
    setIndustry,
    occupation,
    setOccupation,
    startMonth,
    setStartMonth,
    startYear,
    setStartYear,
    endMonth,
    setEndMonth,
    endYear,
    setEndYear,
    so,
    setSo,
    description,
    setDescription,

    certificate,
    setCertificate,
    verifiedCertificate,
    setVerifiedCertificate,
    resume,
    setResume,
    verifiedResume,
    setVerifiedResume,
    additional,
    setAdditional,
    verifiedAdditional,
    setVerifiedAddverifiedAdditional,

    handleUpdateCertificate,
    handleUpdateAdditional,

    data,
  }
}
