import NProgress from "nprogress"
import Router from "next/router"
import React, { useEffect } from "react"
import { useAtomValue, useSetAtom } from "jotai"
import { queryClientAtom } from "jotai/query"
import Head from "next/head"
import { QueryClientProvider, Hydrate } from "@tanstack/react-query"
import { Toaster } from "react-hot-toast"
import { SessionProvider, useSession } from "next-auth/react"

import "./../base.scss"
import "tenka/style.css"
import "swiper/css"
import "swiper/scss/pagination"

import { useWindowFocus } from "../hooks/useWindowFocus"

import AppProvider from "../utils/AppProvider"
import ErrorBoundary from "../utils/ErrorBoundary"
import ProtectedRoute from "../utils/ProtectedRoute"
import { apiTokenAtom, userAtom, useUserQuery } from "../atoms/userAtom"
import { authenticateFetcher } from "../utils/fetcher"

export const HOST = process.env.NEXT_PUBLIC_API_HOST
export const HOSTV1 = process.env.NEXT_PUBLIC_API_HOST_V1

Router.events.on("routeChangeStart", (url) => {
  NProgress.start()
})
Router.events.on("routeChangeComplete", () => {
  return NProgress.done()
})
Router.events.on("routeChangeError", () => {
  return NProgress.done()
})

function handleResizeEvent() {
  let height = window?.innerHeight
  if (height) {
    document.documentElement.style.setProperty(
      "--viewport-height",
      `${height}px`
    )
  }
}

const SetupSession = () => {
  const { data: session } = useSession()
  const { data: user } = useUserQuery()

  const setApiToken = useSetAtom(apiTokenAtom)
  const setUser = useSetAtom(userAtom)

  React.useEffect(() => {
    if (session?.token) {
      setApiToken(session?.token)
      authenticateFetcher(session?.token)
    }
  }, [session])

  React.useEffect(() => {
    if (user?._id) {
      setUser(user)
    }
  }, [user])

  return null
}

function MyApp({ Component, pageProps }) {
  useWindowFocus()

  const queryClient = useAtomValue(queryClientAtom)

  useEffect(() => {
    handleResizeEvent()
    window.addEventListener("resize", handleResizeEvent)
    return () => {
      window.removeEventListener("resize", handleResizeEvent)
    }
  }, [])

  return (
    <QueryClientProvider client={queryClient}>
      <SessionProvider session={pageProps.session}>
        <Hydrate state={pageProps.dehydratedState}>
          <SetupSession />
          <AppProvider>
            <Head>
              <meta
                name="viewport"
                content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, viewport-fit=cover"
              />
            </Head>
            <ErrorBoundary>
              {Component.auth ? (
                <ProtectedRoute required={Component.auth}>
                  <Component {...pageProps} />
                </ProtectedRoute>
              ) : (
                <Component {...pageProps} />
              )}
              <Toaster position="bottom-center" />
            </ErrorBoundary>
          </AppProvider>
        </Hydrate>
      </SessionProvider>
    </QueryClientProvider>
  )
}

export default MyApp
