import { useAtom } from 'jotai'
import React, { useEffect } from 'react'
import { documentFocusAtom } from '../atoms/setupAtom'

export const useWindowFocus = () => {
  const [focus, setFocus] = useAtom(documentFocusAtom)

  useEffect(() => {
    window.onfocus = () => setFocus(true)
    window.onblur = () => setFocus(false)
  }, [])

  return focus
}
