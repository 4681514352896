import axios from "axios"

const API_ENDPOINT = process.env.API_HOST ?? process.env.NEXT_PUBLIC_API_HOST
const API_ENDPOINT_V1 =
  process.env.API_HOST_v1 ?? process.env.NEXT_PUBLIC_API_HOST_v1

export const fetcher = axios.create({
  baseURL: API_ENDPOINT,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
})

export const isFetcherReady = () => {
  // console.log({
  //   Bearer: fetcher?.defaults?.headers?.Authorization?.split(" ")?.[1],
  // })
  if (fetcher?.defaults?.headers?.Authorization?.split(" ")?.[1]) {
    return true
  }
  return false
}

export const fetcherCms = axios.create({
  baseURL: API_ENDPOINT,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
})

export const multipartFetcher = axios.create({
  baseURL: API_ENDPOINT,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/form-data",
  },
})

export const fetchV1 = axios.create({
  baseURL: API_ENDPOINT_V1,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
})

export const fetchSocket = axios.create({
  baseURL: process.env.NEXT_PUBLIC_SOCKET_HOST,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
})

export const authenticateFetcher = (token) => {
  if (token) {
    fetcher.defaults.headers["Authorization"] = `Bearer ${token}`
    fetchV1.defaults.headers["Authorization"] = `Bearer ${token}`
    fetchSocket.defaults.headers["Authorization"] = `Bearer ${token}`
    multipartFetcher.defaults.headers["Authorization"] = `Bearer ${token}`
  }
}
