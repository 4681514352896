import { useEffect, useState } from "react"
import axios from "axios"
import { produce } from "immer"
import { useQuery } from "@tanstack/react-query"
import { atom } from "jotai"

const API_ENDPOINT = "https://twilight-frost-2680.fly.dev"

const locationKeys = {
  allProvince: ["all-province"],
  allKab: (idProv) => ["all-kabupaten", idProv],
  allKec: (idKab) => ["all-kecamatan", idKab],
  allKel: (idKec) => ["all-kelurahan", idKec],
}

const fetcher = axios.create({
  baseURL: API_ENDPOINT,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
})

const defaultProvince = {
  _id: "0000",
  id: "00",
  name: "All Provinces",
  lat: "-0.7893",
  lng: "113.9213",
}
export const provinceAtom = atom(null)
export async function fetchAllProvinces({ signal, all }) {
  try {
    const res = await fetcher({
      method: "GET",
      url: "/prov",
      signal,
    })

    if (res?.status === 200) {
      let sortedProvinces = res?.data?.data.sort(
        (a, b) => Number(a.id) - Number(b.id)
      )

      if (all) {
        let allProvinces = produce(sortedProvinces, (draft) => {
          draft.unshift(defaultProvince)
        })
        return allProvinces
      }

      return sortedProvinces
    }
  } catch (error) {
    console.log({ failed_fetch_provinces: error })
    return null
  }
}

export const useAllProvinceQuery = (all) => {
  const provinceQuery = useQuery(locationKeys.allProvince, ({ signal }) =>
    fetchAllProvinces({ signal, all })
  )
  return provinceQuery
}

export const useProvince = (initialProvince, all) => {
  const { data: allProvinces, isLoading } = useAllProvinceQuery(all)
  const [province, setProvince] = useState(initialProvince)

  useEffect(() => {
    if (isLoading) {
      return
    }
    if (initialProvince?.id) {
      setProvince(initialProvince)
    } else {
      if (allProvinces?.[0]) {
        setProvince(allProvinces?.[0])
      }
    }
  }, [allProvinces, initialProvince, isLoading])

  return {
    allProvinces,
    allProvince: allProvinces,
    province,
    setProvince,
    isLoading,
  }
}

const defaultRegency = {
  _id: "000000",
  id: "0000",
  name: "All Kab./Kota",
  lat: "-0.7893",
  lng: "113.9213",
}
export const regencyAtom = atom(null)
export async function fetchAllRegencies({ signal, all, provinceId }) {
  try {
    const res = await fetcher({
      method: "GET",
      url: `/prov/${provinceId}`,
      signal,
    })

    if (res?.status === 200) {
      let sortedRegencies = res?.data?.kabupaten.sort(
        (a, b) => Number(a.id) - Number(b.id)
      )

      if (all) {
        let allRegencies = produce(sortedRegencies, (draft) => {
          draft.unshift(defaultRegency)
        })
        return allRegencies
      }

      return res?.data?.kabupaten
    }
  } catch (error) {
    console.log({ failed_fetch_regencies: error })
    return null
  }
}
export const useAllRegenciesQuery = (province, all) => {
  const regenciesQuery = useQuery(
    locationKeys.allKab(province?.id),
    ({ signal }) =>
      fetchAllRegencies({ signal, all, provinceId: province?.id }),
    { enabled: Boolean(province?.id && province?.id !== defaultProvince.id) }
  )
  return regenciesQuery
}
export const useRegency = (initialRegency, province, all) => {
  const { data: allRegencies, isLoading } = useAllRegenciesQuery(province, all)
  const [regency, setRegency] = useState(initialRegency)

  useEffect(() => {
    if (isLoading) {
      return
    }
    if (initialRegency?.id) {
      setRegency(initialRegency)
    } else {
      if (allRegencies?.[0]) setRegency(allRegencies?.[0])
    }
  }, [allRegencies, initialRegency, isLoading])

  useEffect(() => {
    if (isLoading) {
      return
    }
    let index = allRegencies?.findIndex((reg) => reg.id === regency?.id)
    if (index == -1 && allRegencies?.length > 0) {
      setRegency(allRegencies[0])
    }
  }, [regency, allRegencies, isLoading])

  return {
    allRegencies,
    allRegency: allRegencies,
    regency,
    setRegency,
    isLoading,
  }
}

export async function fetchAllDistricts({ signal, regencyId }) {
  try {
    const res = await fetcher({
      method: "GET",
      url: `/kab/${regencyId}`,
      signal,
    })

    if (res?.status === 200) {
      return res?.data?.kecamatan
    }
  } catch (error) {
    console.log({ failed_fetch_districts: error })
    return null
  }
}
export const useAllDistrictsQuery = (regency) => {
  const districtsQuery = useQuery(
    locationKeys.allKec(regency?.id),
    ({ signal }) => fetchAllDistricts({ signal, regencyId: regency.id }),
    { enabled: Boolean(regency?.id) }
  )
  return districtsQuery
}
export const useDistrict = (initialDistrict, regency) => {
  const { data: allDistricts, isLoading } = useAllDistrictsQuery(regency)
  const [district, setDistrict] = useState(initialDistrict)

  useEffect(() => {
    if (isLoading) {
      return
    }
    if (initialDistrict?.id) {
      setDistrict(initialDistrict)
    } else {
      if (allDistricts?.[0]) setDistrict(allDistricts?.[0])
    }
  }, [initialDistrict, allDistricts, isLoading])

  useEffect(() => {
    if (isLoading) {
      return
    }
    let index = allDistricts?.findIndex((dis) => dis.id === district?.id)
    if (index == -1 && allDistricts?.length > 0) {
      setDistrict(allDistricts[0])
    }
  }, [district, allDistricts, isLoading])

  return {
    allDistricts,
    district,
    setDistrict,
    isLoading,
  }
}

export async function fetchAllVillages({ signal, districtId }) {
  try {
    const res = await fetcher({
      method: "GET",
      url: `/kec/${districtId}`,
      signal,
    })

    if (res?.status === 200) {
      return res.data.kelurahan
    }
  } catch (error) {
    console.log({ failed_fetch_villages: error })
    return null
  }
}
export const useAllVillagesQuery = (district) => {
  const villagesQuery = useQuery(
    locationKeys.allKel(district?.id),
    ({ signal }) => fetchAllVillages({ signal, districtId: district.id }),
    { enabled: Boolean(district?.id) }
  )
  return villagesQuery
}
export const useVillage = (initialVillage, district) => {
  const { data: allVillages, isLoading } = useAllVillagesQuery(district)
  const [village, setVillage] = useState(initialVillage)

  useEffect(() => {
    if (isLoading) {
      return
    }
    if (initialVillage?.id) {
      setVillage(initialVillage)
    } else {
      if (allVillages?.[0]) setVillage(allVillages?.[0])
    }
  }, [initialVillage, allVillages, isLoading])

  useEffect(() => {
    if (isLoading) {
      return
    }
    let index = allVillages?.findIndex((vil) => vil.id === village?.id)
    if (index == -1 && allVillages?.length > 0) {
      setVillage(allVillages[0])
    }
  }, [village, allVillages, isLoading])

  return {
    allVillages,
    village,
    setVillage,
    isLoading,
  }
}

export const useLocation = (
  initialProvince,
  initialRegency,
  initialDistrict,
  initialVillage,
  allLocations = false
) => {
  const { data: allProvinces, isLoading: isLoadingProvinces } =
    useAllProvinceQuery(allLocations)
  const [province, setProvince] = useState(initialProvince)

  useEffect(() => {
    if (isLoadingProvinces) {
      return
    }
    if (initialProvince?.id) {
      setProvince(initialProvince)
    } else {
      if (allProvinces?.[0]) {
        setProvince(allProvinces?.[0])
      }
    }
  }, [allProvinces, initialProvince, isLoadingProvinces])

  const {
    data: allRegencies,
    isLoading: isLoadingRegencies,
    refetch: refetchAllRegencies,
  } = useAllRegenciesQuery(province, allLocations)
  const [regency, setRegency] = useState(initialRegency)

  useEffect(() => {
    if (
      province?.id &&
      allRegencies?.length > 1 &&
      allRegencies?.[1]?.province_id !== province?.id
    ) {
      refetchAllRegencies()
    }
  }, [province, allRegencies])

  useEffect(() => {
    if (isLoadingRegencies) {
      return
    }
    if (initialRegency?.id) {
      setRegency(initialRegency)
    } else {
      if (allRegencies?.[0]) setRegency(allRegencies?.[0])
    }
  }, [allRegencies, initialRegency, isLoadingRegencies])

  useEffect(() => {
    if (isLoadingRegencies) {
      return
    }
    let index = allRegencies?.findIndex((reg) => reg.id === regency?.id)
    if (index == -1 && allRegencies?.length > 0) {
      setRegency(allRegencies[0])
    }
  }, [regency, allRegencies, isLoadingRegencies])

  const {
    data: allDistricts,
    isLoading: isLoadingDistricts,
    refetch: refetchAllDistricts,
  } = useAllDistrictsQuery(regency)
  const [district, setDistrict] = useState(initialDistrict)

  useEffect(() => {
    if (
      regency?.id &&
      allDistricts?.length > 1 &&
      allDistricts?.[1]?.regency_id !== regency?.id
    ) {
      refetchAllDistricts()
    }
  }, [regency, allDistricts])

  useEffect(() => {
    if (isLoadingDistricts) {
      return
    }
    if (initialDistrict?.id) {
      setDistrict(initialDistrict)
    } else {
      if (allDistricts?.[0]) setDistrict(allDistricts?.[0])
    }
  }, [initialDistrict, allDistricts, isLoadingDistricts])

  useEffect(() => {
    if (isLoadingDistricts) {
      return
    }
    let index = allDistricts?.findIndex((dis) => dis.id === district?.id)
    if (index == -1 && allDistricts?.length > 0) {
      setDistrict(allDistricts[0])
    }
  }, [district, allDistricts, isLoadingDistricts])

  const {
    data: allVillages,
    isLoading: isLoadingVillages,
    refetch: refetchAllVillages,
  } = useAllVillagesQuery(district)
  const [village, setVillage] = useState(initialVillage)

  useEffect(() => {
    if (
      district?.id &&
      allVillages?.length > 1 &&
      allVillages?.[1]?.district_id !== district?.id
    ) {
      refetchAllVillages()
    }
  }, [district, allVillages])

  useEffect(() => {
    if (isLoadingVillages) {
      return
    }
    if (initialVillage?.id) {
      setVillage(initialVillage)
    } else {
      if (allVillages?.[0]) setVillage(allVillages?.[0])
    }
  }, [initialVillage, allVillages, isLoadingVillages])

  useEffect(() => {
    if (isLoadingVillages) {
      return
    }
    let index = allVillages?.findIndex((vil) => vil.id === village?.id)
    if (index == -1 && allVillages?.length > 0) {
      setVillage(allVillages[0])
    }
  }, [village, allVillages, isLoadingVillages])

  return {
    allProvinces,
    allProvince: allProvinces,
    province,
    setProvince,

    allRegencies,
    allRegency: allRegencies,
    regency,
    setRegency,

    allDistricts,
    allDistrict: allDistricts,
    district,
    setDistrict,

    allVillages,
    allVillage: allVillages,
    village,
    setVillage,
  }
}
